/**
 * 由于小程序对svg的表现和h5不一致，
 * 这里做多一层封装。
 */
import React from 'react';
import classnames from 'classnames';
import typeMap from './map';

const Icon = ({ type, className, ...props }) => {
    const SvgComponents = typeMap[type];
    return <SvgComponents className={classnames('tcIcon', className)} {...props} />;
};


export default Icon;